
// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";

// Data
import selectData from "layouts/pages/account/settings/components/BasicInfo/data/selectData";
import MDButton from "../../../../../../components/MDButton";
import {useEffect, useState} from "react";
import MDInput from "../../../../../../components/MDInput";
import userService from "../../../../../../services/user.service";
import customeService from "../../../../../../services/custome.service";
import LoadingOverlay from 'react-loading-overlay';
import {doLogout} from "../../../../../../services/auth.service";
import MDSnackbar from "../../../../../../components/MDSnackbar";
import Checkbox from "@mui/material/Checkbox";

function BasicInfo() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [streetName, setStreetName] = useState("");
  const [unitNumber, setUnitNumber] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [sex, setSex] = useState();
    const [checkBox, setCheckBox] = useState(false);
  const [birthday, setBirthday] = useState();
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [province, setProvince] = useState("");
  const [city, setCity] = useState("");
  useEffect(()=>{
    userService.getProfile()
        .then((val) =>{
            val = val.data;
            console.log(val)
            setFirstName(val["first_name"]);
            setLastName(val["last_name"]);
            setPhone(val["phone"]);
            setEmail(val["email"]);
            setStreetName(val["street"]);
            setUnitNumber(val["unit"]);
            setPostalCode(val["postal_code"]);
            setCountry(val["country"]);
            setProvince(val["province"]);
            setCity(val["city"]);
            setCheckBox(val["send_mail"]);
        })
        .catch((err) =>{

          if(err.response.status === 401){
              //doLogout();
          }
        })

  }, [setFirstName, setLastName, setPhone, setEmail, setUnitNumber,
            setStreetName, setPostalCode, setCountry,setProvince, setCity]);

    const [infoSB, setInfoSB] = useState(false);
    const [errorSB, setErrorSB] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const renderSB = (
        <div>
            <MDSnackbar
              color="success"
              icon="notifications"
              title="Amenity System"
              content="Your data saved successfully"
              dateTime=""
              open={infoSB}
              onClose={()=>setInfoSB(false)}
              close={()=>setInfoSB(false)}  />

            <MDSnackbar
              color="error"
              icon="error"
              title="Amenity System"
              content={errorMessage}
              dateTime=""
              open={errorSB}
              onClose={()=>setErrorSB(false)}
              close={()=>setErrorSB(false)}/>
        </div>
    );

  const submitInfo = (e) => {
    e.preventDefault();
    setActiveLoading(true);
    userService.saveInfo(firstName, lastName, birthday, phone.toString(), checkBox.toString())
        .then((val) =>{
            console.log("hello");
            setActiveLoading(false);
            setInfoSB(true);
            window.location.reload(true);
        })
        .catch((err) =>{
          console.log(err)
            setActiveLoading(false);
            setErrorMessage(err);
            setErrorSB(true);
        })
  }

  const [activeLoading, setActiveLoading] = useState(false);

  return (
      <LoadingOverlay active={activeLoading} spinner text='Saving content...'>
          {renderSB}
        <Card id="basic-info" sx={{ overflow: "visible" }}>
            <MDBox p={3}>
                <MDTypography variant="h5">Basic Info</MDTypography>
            </MDBox>
            <MDBox component="form" pb={3} px={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} sx={{"marginBottom":"10px"}}>
                <Checkbox checked={checkBox} onChange={(e)=> setCheckBox(e.target.checked)} />
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    onClick={(e)=> setCheckBox(!checkBox)}
                    color="text"
                    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                  >
                    &nbsp;&nbsp;Receive Booking Email&nbsp;
                  </MDTypography>
                  <hr/>
              </Grid>

              <Grid item xs={12} sm={4}>
                <FormField label="First Name"
                           placeholder="Alec"
                           value={firstName}
                           onChange={(e) => setFirstName(e.target.value)} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormField label="Last Name"
                           placeholder="Thompson"
                           value={lastName}
                           onChange={(e) => setLastName(e.target.value)} />
              </Grid>
              <Grid item xs={12} sm={4}>
                  <FormField label="Birthday" type={"date"}
                             placeholder="Thompson"
                             value={birthday}
                             onChange={(e) => setBirthday(e.target.value)} />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormField
                  label="Email"
                  placeholder="example@email.com"
                  value={email}
                  readOnly
                  onChange={(e) => setEmail(e.target.value)}
                  inputProps={{ type: "email", readOnly: "true" }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField
                  label="Phone Number"
                  placeholder="+40 735 631 620"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  inputProps={{ type: "text" }}
                />
              </Grid>
               <Grid item xs={12} md={4}>
                <FormField
                  label="Country"
                  value={country}
                  readOnly
                  inputProps={{ type: "text", readOnly:"true" }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormField
                  label="Province"
                  value={province}
                  readOnly
                  inputProps={{ type: "text", readOnly:"true" }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormField
                  label="City"
                  value={city}
                  readOnly
                  inputProps={{ type: "text", readOnly:"true" }}
                />
              </Grid>


              <Grid item xs={12} sm={8}>
                <FormField
                  label="Street Name"
                  placeholder="Rollins Road"
                  value={streetName}
                  onChange={(v)=>setStreetName(v.target.value)}
                  inputProps={{ type: "text", readOnly: "true" }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormField
                  label="Unit"
                  placeholder="4321"
                  value={unitNumber}
                  readOnly
                  inputProps={{ type: "text", readOnly:"true" }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormField
                  label="Postal Code"
                  style={{"display":"none"}}
                  placeholder="V1C 2M8"
                  value={postalCode}
                  onChange={(v)=>setPostalCode(v.target.value)}
                  inputProps={{ type: "text", readOnly: "true" }}
                />
              </Grid>

              <MDBox ml="auto" mt={4}>
                <MDButton variant="gradient" color="dark" size="small" onClick={submitInfo}>
                  update information
                </MDButton>
              </MDBox>
            </Grid>

          </MDBox>
        </Card>
      </LoadingOverlay>
  );
}

export default BasicInfo;
