import axios from "axios";
import {getPrivateURL, getPublicURL} from "./core.service";

axios.defaults.withCredentials = true;

export const getParking = () => {
  return axios.get(`${getPrivateURL()}parking/`, {withCredentials:true} );
};
export const getBuildingParking = (building_id) => {
  return axios.get(`${getPrivateURL()}parking/` + building_id, {withCredentials:true} );
};
export const getParkingLevel = (parking_id) => {
  if (parking_id === undefined || parking_id === null){
    parking_id = ""
  }
  return axios.get(`${getPrivateURL()}parking/level/` + parking_id, {withCredentials:true} );
};
export const getParkingSpot = (level_id) => {
  if (level_id === undefined || level_id === null){
    level_id = ""
  }
  return axios.get(`${getPrivateURL()}parking/spot/` + level_id, {withCredentials:true} );
};

export const insertParking = (values) => {
  return axios.post(`${getPrivateURL()}parking/`, values, {withCredentials:true} );
};
export const insertParkingSpot = (level_id, values) => {
  return axios.post(`${getPrivateURL()}parking/spot/` + level_id, values, {withCredentials:true} );
};
export const insertParkingLevel = (parking_id, values) => {
  return axios.post(`${getPrivateURL()}parking/level/` + parking_id, values, {withCredentials:true} );
};




export const getMyReservation = () => {
  return axios.get(`${getPrivateURL()}parking_booking/` , {withCredentials:true} );
};
export const getSpotType = () => {
  return axios.get(`${getPrivateURL()}parking_spot/` , {withCredentials:true} );
};
export const insertSpotType = (data) => {
  return axios.post(`${getPrivateURL()}parking_spot/` ,  data , {withCredentials:true} );
};

export const removeSpotType = (spot_id) => {
  return axios.post(`${getPrivateURL()}parking_spot/` + spot_id , {withCredentials:true} );
};


// export const updateParking = (Parking_id, data ) => {
//   return axios.put(`${getPrivateURL()}parking/${Parking_id}`, data, {withCredentials:true});
// };
// export const updateParkingLevel = (Parking_id, level_id, data ) => {
//   return axios.put(`${getPrivateURL()}parking/${Parking_id}/level/${level_id}`, data, {withCredentials:true});
// };
// export const updateParkingSpot = (Parking_id, data ) => {
//   return axios.put(`${getPrivateURL()}parking/${Parking_id}`, data, {withCredentials:true});
// };
export const removeParking = (parking_id ) => {
  return axios.delete(`${getPrivateURL()}parking/${parking}`, {withCredentials:true});
};
export const removePackageLevel = (level_id ) => {
  return axios.delete(`${getPrivateURL()}parking/level/${level_id}`, {withCredentials:true});
};
export const removePackageSpot = (spot_id ) => {
  return axios.delete(`${getPrivateURL()}parking/spot/${spot_id}`, {withCredentials:true});
};
export const disableParking = (parking_id ) => {
  return axios.patch(`${getPrivateURL()}packages/${parking_id}`, {withCredentials:true});
};
export const disableParkingLevel = (level_id ) => {
  return axios.patch(`${getPrivateURL()}packages/level/${level_id}`, {withCredentials:true});
};
export const disablePackageSpot = (spot_id ) => {
  return axios.patch(`${getPrivateURL()}packages/spot/${spot_id}`, {withCredentials:true});
};
export const bookParking = (data) => {
  return axios.post(`${getPrivateURL()}parking_booking/`, data, {withCredentials:true} );
};
const ParkingService = {

  bookParking,
  getParking,
  getBuildingParking,
  getParkingSpot,
  getParkingLevel,
  insertParking,
  insertParkingLevel,
  insertParkingSpot,
  removeParking,
  removePackageLevel,
  removePackageSpot,
  disableParking,
  disableParkingLevel,
  disablePackageSpot,
  getMyReservation,


  getSpotType,
  insertSpotType,
  removeSpotType,
};
export default ParkingService;
