import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import BookingCard from "examples/Cards/BookingCard";
// import Modal from 'react-modal';

// Images
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import { useState, useEffect } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Modal from '@mui/material/Modal';
import Autocomplete from "@mui/material/Autocomplete";
import {Link} from "react-router-dom";
import MDButton from "../../../components/MDButton";
import DataTable from "../../../examples/Tables/DataTable";
import MDSnackbar from "../../../components/MDSnackbar";
import FormField from "../account/components/FormField";
import ClientsService, {getUnitMembers} from "../../../services/clients.service";
import MDBadge from "../../../components/MDBadge";
import GHIconButton from "../../../components/GHIconButton";
import InstancesService from "../../../services/instances.service";
import TextField from "@mui/material/TextField";
import clientsService from "../../../services/clients.service";
import {getCurrentInstanceId} from "../../../services/auth.service";
import validateForm from "../../../utils";
import {unitColumns, userColumns, userInsideColumns} from "../../../components/Utils/HeaderColumns";
import {modalStyle, modalStyleUser} from "../../../components/Utils/ModalStyle";
import * as Yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import MDInput from "../../../components/MDInput";


import LoadingOverlay from 'react-loading-overlay';
function Units() {


    const defaultInputValues = { unitNumber: '', floor: '', section: '',postalCode: '', building: {} };

    const [values, setValues] = useState(defaultInputValues);

    const validationSchema = Yup.object().shape({
        unitNumber: Yup.string()
            .max(4, 'Unit Number must be at maximum 4 characters')
            .required('Unit Number Name is required'),
        floor: Yup.string()
            .max(4, 'Floor must be at maximum 4 characters'),
        section: Yup.string()
            .max(4, 'Section must be at maximum 4 characters'),
    });

    const { register, handleSubmit, formState: { errors  } } =
            useForm({mode:"all", resolver:yupResolver(validationSchema)});

    const handleChange = (value) => {
        setValues(value);
        setIsValid(validationSchema.isValidSync(values));
    };

    // console.log(values);

    const [isValid, setIsValid] = useState(false);



    const [infoSB, setInfoSB] = useState(false);
    const [errorSB, setErrorSB] = useState(false);
    const openErrorSB = () => setErrorSB(true);
    const closeErrorSB = () => setErrorSB(false);
    const openInfoSB = () => setInfoSB(true);
    const closeInfoSB = () => setInfoSB(false);
    const [errorMessage, setErrorMessage] = useState("");
    const renderSB = () =>(
        <div>
            <MDSnackbar
              color="success"
              icon="notifications"
              title="Amenity System"
              content="Your data saved successfully"
              dateTime=""
              open={infoSB}
              onClose={()=>setInfoSB(false)}
              close={()=>setInfoSB(false)}  />

            <MDSnackbar
              color="error"
              icon="error"
              title="Amenity System"
              content={errorMessage}
              dateTime=""
              open={errorSB}
              onClose={()=>setErrorSB(false)}
              close={()=>setErrorSB(false)}/>
        </div>
    );




    const [editMode, setEditMode] = useState(false);

    const [clickedItemId, setClickedItemId] = useState(0);
    const [selectedBuilding, setSelectedBuilding] = useState({});
    const [buildingList, setBuildingList] = useState([]);
    // const [clientList, setClientList] = useState([]);
    const [openUserModel, setOpenUserModel] = useState(false);

    const [openInsertModel, setOpenInsertModel] = useState(false);

    const handleOpen = (event, model) => {
        switch (model){
            case "insert":
                setOpenInsertModel(true);
                setEditMode(false);
                // setModalTitle("Insert New Unit");
                break;
            case "edit" :
                // requestUserList(event.target.id);
                setOpenInsertModel(true);
                setEditMode(true);
                selectUnit(event.target.id)
                // setModalTitle("Edit Unit");
                break;
            case "user" :
                // requestUserList(event.target.id);
                setOpenUserModel(true);
                break;
            case "confirm_change_status":
                // requestUserList(event.target.id);
                // setOpenDialog(true);
                break;
        }
    }
    const handleClose = (model) => {
        switch (model){
            case "insert":
                setValues(defaultInputValues);
                setOpenInsertModel(false);
                break;
            case "user":
                setOpenUserModel(false);
                break;
        }
    }

    useEffect(() => {
        loadBuildings(); }, []);



    const [userTableData, setUserTableData] = useState({"columns" :userInsideColumns, "rows":[]});
    const [selectedClientTitle, setSelectedClientTitle] = useState("");



    const requestUserList = (unitId) => {
        console.log(unitId)
        setUserTableData({"columns" :userInsideColumns, "rows":[]});
        const clickOnRowUser = (e) =>{
            console.log(e.target);
        }
        ClientsService.getUnitMembers(unitId)
            .then((res) => {
                console.log(res.data);
                let data = res.data;
                setUserTableData( {
                    "columns": userInsideColumns,
                    "rows": data.map(function(element) {
                        return {
                                  id: element.id,
                                  username: element.user.username,
                                  name: element.user.first_name + " " + element.user.last_name,
                                  unit: element.unit,
                                  building: element.building,
                                  postal_code: element.postal_code,
                                  role: element.role,
                                  status: <MDBadge badgeContent={element.status} size="sm" container
                                                   color={element.status === "active" ? "success" : "error"} />,
                                  action: (
                                      <div>
                                          <GHIconButton size={"medium"} onClick={clickOnRowUser}
                                                        id={element.id} title={"Remove From Client"}
                                                        color={"error"} icon={"delete"}/>
                                      </div>
                                  ),
                            }
                    })
                })
            })
            .catch((error) => {
                console.log(error);
            });

    }


    const selectUnit = (unit_id) =>{
        ClientsService.getUnit(unit_id)
            .then((response)=>{
                let tempData  = {
                    'unitNumber':response.data["unit"],
                    'floor':response.data["floor"],
                    'postal_code':response.data["postal_code"],
                    'section':response.data["section"],
                };
                setValues(tempData);
            }).catch((reason)=>{
                setErrorMessage(reason.response.data.status.toString());
                openErrorSB();
            });
    }

    function handleClickItem(elem) {
        // handleOpenDialog();
        // console.log(elem.target.id)
        let actionToDo = elem.target.getAttribute("action");
        let rowName = elem.target.getAttribute("rowname");
        let rowId = elem.target.id;
        // console.log(rowName)
        // console.log(rowId)
        setClickedItemId(rowId);
        setSelectedClientTitle(rowName);
        switch (actionToDo) {
            case "user_list":
                requestUserList(rowId)
                handleOpen(elem,"user");
                break;
            case "edit":
                handleOpen(elem,"edit");
                break;
            case "change_status":
                handleOpen(elem,"confirm_change_status");
                break;
        }
    }



    const [unitTableData, setUnitTableData] =
        useState({"columns" : unitColumns, "rows":[]});



    const saveDataClicked = (e) =>{
        e.preventDefault();

        // handleChange({ ...values, building: selectedBuilding.id });
        if(isValid){
            let sendData = {
                "building":selectedBuilding.id.toString(),
                "unit_number":values.unitNumber.toString(),
                "floor":values.floor.toString(),
                "section":values.section.toString(),
                // "postal_code":values.postalCode,
            }
            if (editMode ){
                ClientsService.updateUnit(clickedItemId, sendData)
                .then((response) =>{
                    console.log(response);
                    setInfoSB(true);
                    setValues(defaultInputValues);

                    handleClose("insert");

                    openInfoSB();
                    loadUnits(selectedBuilding.id);

                    window.location.reload();
                })
                .catch((err) => {
                    console.log(err);
                    setErrorMessage(err.response.data.status.toString());
                    openErrorSB();
                });
            }else{
                ClientsService.insertUnit(sendData)
                .then((response) =>{
                    console.log(response);
                    setInfoSB(true);
                    setValues(defaultInputValues);

                    handleClose("insert");

                    openInfoSB();
                    loadUnits(selectedBuilding.id);

                    window.location.reload();
                })
                .catch((err) => {
                    console.log(err);
                    setErrorMessage(err.response.data.status.toString());
                    openErrorSB();
                });
            }

        }
    }


    const renderUserListModal = () => {

        return <Modal open={openUserModel} onClose={(e)=>{handleClose("user")}} color={"warning"} >
          <MDBox sx={modalStyleUser}>
            <Card id="basic-info" sx={{ overflow: "visible" }}>
              <MDBox p={3}>
                <MDTypography variant="h5">Unit {selectedClientTitle} Member's List</MDTypography>
              </MDBox>
              <MDBox component="form" pb={3} px={3}>
                 <Grid pb={2}  >
                    <MDButton color={"info"} size={"large"} variant={"gradient"}
                              to={"/users/insert?client_id=" + clickedItemId} component={Link} target={"_blank"}  >
                        <Icon>add</Icon>Add Member To unit {selectedClientTitle}
                    </MDButton>
                 </Grid>
                <Grid container spacing={3}>

                    <DataTable table={userTableData} showTotalEntries canSearch />
                </Grid>
              </MDBox>
            </Card>
          </MDBox>
        </Modal>
    };



    const renderInsertModal = () => {
        return <Modal open={openInsertModel} onClose={(e)=>{handleClose("insert")}} color={"warning"} >
          <MDBox sx={modalStyle}>
            <Card id="basic-info" sx={{ overflow: "visible" }}>
              <MDBox p={3}>
                <MDTypography variant="h5">{editMode ? "Edit Unit" : "Insert New Unit"}</MDTypography>
              </MDBox>
              <MDBox component="form" pb={3} px={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <TextField
                          required
                          fullWidth
                          error={!!errors.unitNumber}
                          helperText={errors.unitNumber?.message}
                          value={values.unitNumber}
                          {...register("unitNumber")}
                          onChange={(event) =>
                              handleChange({ ...values, unitNumber: event.target.value })}
                          label="Unit Number" placeholder="4578"  />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                          required
                          fullWidth
                          error={!!errors.floor}
                          helperText={errors.floor?.message}
                          value={values.floor}
                          {...register("floor")}
                          onChange={(event) =>
                              handleChange({ ...values, floor: event.target.value })}
                          label="Floor" placeholder="2"  />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                          required
                          fullWidth
                          error={!!errors.section}
                          helperText={errors.section?.message}
                          value={values.section}
                          {...register("section")}
                          onChange={(event) =>
                              handleChange({ ...values, section: event.target.value })}
                          label="Section" placeholder="2B"  />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                          required
                          fullWidth
                          sx={{"display":"none"}}
                          error={!!errors.postalCode}
                          helperText={errors.postalCode?.message}
                          value={values.postalCode}
                          {...register("postalCode")}
                          onChange={(event) =>
                              handleChange({ ...values, postalCode: event.target.value })}
                          label="Postal Code" placeholder="23VC"  />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <MDButton color={"info"} disabled={!isValid} size={"large"} onClick={saveDataClicked} >Save Data</MDButton>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </MDBox>
        </Modal>
    };

    // const loadClients = () => {
    //     clientsService.getClients()
    //     .then((res) => {
    //         let defaultClient = {"id":"", "client_name":"-- All Clients --" }
    //         if(res.data === null || res.data === undefined || res.data === ""){
    //             res.data = [];
    //         }
    //         res.data.unshift(defaultClient);
    //         setBuildingList(res.data);
    //         setSelectedBuilding(res.data[0]);
    //
    //         loadUnits(res.data[0].id);
    //     })
    //     .catch((e) => {
    //         console.log(e.toString());
    //     });
    // }

    const loadBuildings = () => {
        clientsService.getBuildings()
        .then((res) => {
            // let defaultClient = {"id":"", "name":"-- All Buildings --", "client":"All Client" }
            // if(res.data === null || res.data === undefined || res.data === ""){
            //     res.data = [];
            // }
            // res.data.unshift(defaultClient);
            setBuildingList(res.data);
            setSelectedBuilding(res.data[0]);
            setValues({...values, building: res.data[0]});

            loadUnits(res.data[0].id);
        })
        .catch((e) => {
            console.log(e.toString());
        });
    }

    const [activeLoading, setActiveLoading] = useState(false);
    const loadUnits = (building_id) => {

        if(building_id === undefined){
            building_id = "";
        }

        setActiveLoading(true);
        handleChange({ ...values, building: building_id })
        setUnitTableData({"columns" : unitColumns, "rows":[]})
        ClientsService.getBuildingUnits(building_id)
        .then((res) => {
            setActiveLoading(false);
            // console.log(res.data);
            setUnitTableData({
                "columns": unitColumns,
                "rows": res.data.map(function(element) {
                        return {
                          id: element.id,
                          unit: element.unit ,
                          floor: element.floor ,
                          section: element.section ,
                          client: element.client,
                          building: element.building,
                          members: element.members,
                          action: (
                              <div>
                                  {/*<GHIconButton onClick={handleClickItem} id={element.id} action={"user_list"}*/}
                                  {/*              rowName={element.unit} title={"Members"} color={"info"}*/}
                                  {/*              icon={"list_alt"} size={"medium"}  />*/}
                                  <GHIconButton onClick={handleClickItem} id={element.id} action={"edit"}
                                                rowName={element.unit} title={"Edit"} color={"warning"}
                                                icon={"edit"} size={"medium"}  />

                                  </div>
                          ),
                        }
                    })
            })

        })
        .catch((reason) => {
          console.log(reason);
          setActiveLoading(false);
          setErrorMessage(reason.response.data.status.toString());
        });
    }

    const renderUnitList = () => {
          return(
              <MDBox my={3}>
                {renderSB()}
                {renderInsertModal()}
                {renderUserListModal()}
              <MDBox  display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
                <MDButton variant="gradient" color="warning" size="large"
                          onClick={(e) => {handleOpen(e,"insert")}}>
                  new Unit
                </MDButton>
                <MDBox display={"flex"} >
                    <Autocomplete
                        sx={{ width: 300 }}
                        value={selectedBuilding}
                        options={buildingList}
                        getOptionLabel={(option) => option.name + " (" + option.client + ") "}
                        onChange={(event,value) => {
                            setSelectedBuilding(value);

                            setValues({...values, building: value});
                            loadUnits(value.id);
                        }}
                        renderInput={(params) =>
                            <TextField  {...params} label={"Buildings"} />
                        }
                      />
                </MDBox>
              </MDBox>
              <Card>
              <LoadingOverlay active={activeLoading} spinner text='Loading...'>
                <DataTable showTotalEntries={true} table={unitTableData} entriesPerPage={false} canSearch />
              </LoadingOverlay>
              </Card>
            </MDBox>
          )
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            {renderUnitList()}
            <Footer />
        </DashboardLayout>
    );
}

export default Units;
