import axios from "axios";

// const DOMAIN = "amenity.ghorbanali.com"
// const DOMAIN = "test.amenitybooking.ca"
// const PORT = "8081"


// const PROTOCOL = "https"
// const DOMAIN = "api.amenitybooking.ca"
// const PORT = 443
// alert(process.env.REACT_API_HOST)
// const BASE_API_URL = "https://api2.amenitybooking.ca/";
const BASE_API_URL = "https://api.amenitybooking.ca/";
// const BASE_API_URL = "http://127.0.0.1:8081/";
const PUBLIC_API_URL = BASE_API_URL + "api/public/";
const PRIVATE_API_URL = BASE_API_URL + "api/private/";

export const getPublicURL = () => { return PUBLIC_API_URL; };
export const getPrivateURL = () => { return PRIVATE_API_URL; };

const CoreService = {
  getPrivateURL,
  getPublicURL,
  BASE_API_URL,
};
export default CoreService;
