import {useState} from "react";
import MDSnackbar from "../../../../components/MDSnackbar";
import * as React from "react";
import ElevatorsService from "../../../../services/elevators.service";
import Grid from "@mui/material/Grid";
import MDBox from "../../../../components/MDBox";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import MDDatePicker from "../../../../components/MDDatePicker";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, ListItem, ListItemButton, Paper,
    StepContent,
    StepLabel
} from "@mui/material";
import Icon from "@mui/material/Icon";
import MDButton from "../../../../components/MDButton";
import {reserveParkingColumns} from "../../../../components/Utils/HeaderColumns";
import GHIconButton from "../../../../components/GHIconButton";
import Card from "@mui/material/Card";
import MDTypography from "../../../../components/MDTypography";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabelClass from "../../../../assets/theme/components/stepper/stepLabel";
import Typography from "@mui/material/Typography";
import DataTable from "../../../../examples/Tables/DataTable";
import DashboardLayout from "../../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";
import Footer from "../../../../examples/Footer";
import ParkingService from "../../../../services/parking.service";
import FormField from "../../account/components/FormField";


function ParkingReserve() {

    const [infoSB, setInfoSB] = useState(false);
    const [errorSB, setErrorSB] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const openInfoSB = () => setInfoSB(true);
    const closeInfoSB = () => setInfoSB(false);
    const openErrorSB = () => setErrorSB(true);
    const closeErrorSB = () => setErrorSB(false);
    const renderSB  = () => (
        <div>
            <MDSnackbar
              color="success"
              icon="notifications"
              title="Amenity System"
              content="Your data saved successfully"
              dateTime=""
              open={infoSB}
              onClose={()=>setInfoSB(false)}
              close={()=>setInfoSB(false)}  />

            <MDSnackbar
              color="error"
              icon="error"
              title="Amenity System"
              content={errorMessage}
              dateTime=""
              open={errorSB}
              onClose={()=>setErrorSB(false)}
              close={()=>setErrorSB(false)}/>
        </div>
    );
    const [date,setDate] = useState("")
    const [startTime,setStartTime] = useState("")
    const [endTime,setEndTime] = useState("")
    const [parkingList, setParkingList] = React.useState([]);
    const [selectedParking, setSelectedParking] = React.useState({"name":"Select An Parking"});
    const [spotTypeList, setSpotTypeList] = React.useState([]);
    const [selectedSpotType, setSelectedSpotType] = React.useState({"name":"Select Spot Type"});
    const loadParkingList =   () =>{
        ParkingService.getParking()
            .then((res) => {
                console.log(res.data)
                setParkingList(res.data);
                if (res.data !== undefined && res.data !== null){
                    setSelectedParking(res.data[0])
                }
            })
            .catch((reason)=>{
                setErrorMessage(reason.response.data.status.toString());
                openErrorSB();
            });
    }
    const loadSpotTypeList =   () =>{
        ParkingService.getSpotType()
            .then((res) => {
                console.log(res.data)
                setSpotTypeList(res.data);
                if (res.data !== undefined && res.data !== null){
                    setSelectedSpotType(res.data[0])
                }
            })
            .catch((reason)=>{
                setErrorMessage(reason.response.data.status.toString());
                openErrorSB();
            });
    }
    let currentDate = new Date().getFullYear().toString() + "-" + (new Date().getMonth()+1).toString() + "-" + new Date().getDate().toString();
    let maxDate = new Date().getFullYear().toString() + "-" + (new Date().getMonth()+1).toString() + "-" + (new Date().getDate() + 10).toString();
    // alert(currentDate)
    const [selectedIndex, setSelectedIndex] = React.useState(1);

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
    };

    const [current, setCurrent] = useState(0);
    const handleButton = (index) => {
        setCurrent(index);
    }
    let steps = [
          {
              label: 'Select Parking',
              description: ``,
              input:<Grid item xs={12} mb={2} sm={12} sx={{ mt: 2 }} color={"white"} bgcolor={"white"} >
                    <MDBox variant="gradient" color={"white"} borderRadius="md" shadow="md" coloredShadow={"dark"} >
                        <Autocomplete
                            defaultValue={selectedParking}
                            clearIcon={false}
                            getOptionLabel={(option) =>
                                option.name ||  "--loading--"}
                            options={parkingList}
                            onChange={(e,v)=> setSelectedParking(v)}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </MDBox>
                  </Grid>
          },
          {
              label: 'Select Spot Type',
              description: ``,
              input:<Grid item xs={12} mb={2} sm={12} sx={{ mt: 2 }} color={"white"} bgcolor={"white"} >
                    <MDBox variant="gradient" color={"white"} borderRadius="md" shadow="md" coloredShadow={"dark"} >
                        <Autocomplete
                            defaultValue={selectedSpotType}
                            clearIcon={false}
                            getOptionLabel={(option) => option.spot_type ||  "--loading--"}
                            options={spotTypeList}
                            onChange={(e,v)=> setSelectedSpotType(v)}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </MDBox>
                  </Grid>
          },
          {
                label: 'Select Date',
                description: '' ,
                input:
                    <Grid container spacing={3} mb={2}>
                      <Grid item xs={12} sm={12} sx={{ mt: 2 }}>
                          <MDBox variant="gradient" color={"white"} borderRadius="md" shadow="md" coloredShadow={"dark"} >
                          <MDDatePicker
                              fullWidth
                              value={date}
                              onChange={(date)=>setDate(date[0])}
                              input={{ placeholder: "Select Date" }}
                                options={{
                                    minDate: currentDate,
                                    maxDate:maxDate,
                                    enableTime: false,
                                    altInput: true,
                                    position: 'below',
                                }}/>
                    </MDBox>
                      </Grid>
                    </Grid>
          },
          {
            label: 'Select Time',
            description:'',
            input: <Grid container spacing={3} mb={2}>
                      <Grid item xs={12} sm={6} sx={{ mt: 2 }}>
                          <MDBox variant="gradient" color={"white"} borderRadius="md" shadow="md" coloredShadow={"dark"} >
                              {/*<MDDatePicker*/}
                              {/*    fullWidth*/}
                              {/*    value={startTime}*/}
                              {/*    onChange={(date)=>setStartTime(date[0])}*/}
                              {/*    input={{ placeholder: "From" }}*/}
                              {/*      options={{*/}
                              {/*          mode: "time",*/}
                              {/*          enableTime: true,*/}
                              {/*          enableDate: false,*/}
                              {/*          altInput: true,*/}
                              {/*          position: 'below',*/}
                              {/*          time_24hr: true,*/}
                              {/*          dateFormat: "H:i",*/}
                              {/*      }}/>*/}

                              <FormField
                                onChange={e => setStartTime(e.target.value)}
                                value={startTime}
                                label="Start Time"
                                placeholder="18:30"
                                sx={{"margin":"10px"}}
                                type={"time"} />
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} sm={6} sx={{ mt: 2 }}>
                          <MDBox variant="gradient" color={"white"} borderRadius="md" shadow="md" coloredShadow={"dark"} >
                          {/*<MDDatePicker*/}
                          {/*    fullWidth*/}
                          {/*    value={endTime}*/}
                          {/*    onChange={(date)=>setEndTime(date[0])}*/}
                          {/*    input={{ placeholder: "To" }}*/}
                          {/*      options={{*/}
                          {/*          mode: "time",*/}
                          {/*          minDate: startTime,*/}
                          {/*          enableTime: true,*/}
                          {/*          enableDate: false,*/}
                          {/*          altInput: true,*/}
                          {/*          position: 'below',*/}
                          {/*          time_24hr: true,*/}
                          {/*          dateFormat: "H:i",*/}
                          {/*      }}/>*/}
                              <FormField
                                onChange={e => setEndTime(e.target.value)}
                                value={endTime}
                                label="End Time"
                                placeholder="18:30"
                                sx={{"margin":"10px"}}
                                type={"time"} />
                    </MDBox>
                      </Grid>
                    </Grid>
          },
          {
            label: 'Available Places',
            description: ``,
            input:
                <MDBox sx={{ width: '100%',marginBottom:'12px' }} variant={"gradient"} coloredShadow={"dark"} borderRadius={"12px"} shadow={true} >
                    <MDButton sx={{"margin":"6px"}}  onClick={e=> {handleButton(1)}} variant={"contained"} color={current === 1 ? "light" : "info"} size={"small"} circular={true} >Level 1 - Spot 31</MDButton>
                    <MDButton sx={{"margin":"6px"}}  onClick={e=> {handleButton(2)}} variant={"contained"} color={current === 2 ? "light" : "info"} size={"small"} circular={true} >Level 3 - Spot 34</MDButton>
                    <MDButton sx={{"margin":"6px"}}  onClick={e=> {handleButton(3)}} variant={"contained"} color={current === 3 ? "light" : "info"} size={"small"} circular={true} >Level 4 - Spot 21</MDButton>
                    <MDButton sx={{"margin":"6px"}}  onClick={e=> {handleButton(4)}} variant={"contained"} color={current === 4 ? "light" : "info"} size={"small"} circular={true} >Level 4 - Spot 30</MDButton>
                    <MDButton sx={{"margin":"6px"}}  onClick={e=> {handleButton(5)}} variant={"contained"} color={current === 5 ? "light" : "info"} size={"small"} circular={true} >Level 5 - Spot 11</MDButton>
                    <MDButton sx={{"margin":"6px"}}  onClick={e=> {handleButton(6)}} variant={"contained"} color={current === 6 ? "light" : "info"} size={"small"} circular={true} >Level 5 - Spot 11</MDButton>
                </MDBox>

          },
          {
            label: 'Create Booking',
            description: `Do you confirm Booking Term ?`,
            input:<></>
          },
    ];



    const [activeStep, setActiveStep] = React.useState(0);

    const [elevatorBooking, setElevatorBooking] = React.useState([]);

    const handleNext = () => {
        if (activeStep === steps.length - 1){
            let data = {
                "parking":selectedParking.id.toString(),
                "date":date.toString(),
                "start":startTime.toString(),
                "end":endTime.toString(),
                "position":current.toString(),
            }
            ParkingService.bookParking(data)
                .then((value)=>{
                    openInfoSB();
                    window.location.reload();
                })
                .catch((reason)=>{
                    setErrorMessage(reason.response.data.status.toString());
                    openErrorSB();
                });
        }else{
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleFinish = () => {setActiveStep((prevActiveStep) => prevActiveStep + 1);};

    const handleBack = () => {setActiveStep((prevActiveStep) => prevActiveStep - 1);};

    const handleReset = () => {setActiveStep(0);};



    const [clickedItemId, setClickedItemId] = useState(0);


    const handleOpen = (event, model) => {
        switch (model){
            case "cancel":
                handleOpenDialog(model);
                break;
        }
    }

    const [openDialog, setOpenDialog] = useState(false);
    const [dialogAction, setDialogAction] = useState("");
    const handleOpenDialog = (action) => {
        setOpenDialog(true);
        setDialogAction(action)
    }
    const handleCloseDialog = () => setOpenDialog(false);

    const handleAgreeDeleteDialog = () => {
        if (dialogAction === "cancel") {
            ElevatorsService.cancelBookElevator(clickedItemId)
                .then((response) => {
                    openInfoSB();
                    window.location.reload();
                })
                .catch((err) => {
                    console.log(err);
                    setErrorMessage(err);
                    openErrorSB();
                });

        }
    }


    const renderDialog = () => {
        return (
            <Dialog
                open={openDialog}
                keepMounted
                onClose={handleCloseDialog}
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogTitle><Icon color={"warning"} >warning</Icon>{" Delete/Disable confirmation"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Are you sure to Delete/Disable this item ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <MDButton onClick={handleCloseDialog} >Disagree</MDButton>
              <MDButton onClick={handleAgreeDeleteDialog}  size={"large"}  color={"info"}  >Agree</MDButton>
            </DialogActions>
          </Dialog>
        )
    }


    const clickItem = (elem) =>{
        let actionToDo = elem.target.getAttribute("action");
        let rowId = elem.target.id;
        setClickedItemId(rowId);
        switch (actionToDo) {
            case "cancel":
                handleOpen(elem,"cancel");
                break;
        }
    }
    const [parkingTableData, setParkingTableData] = useState({"columns" : reserveParkingColumns, "rows":[]});
    const loadParkingReserved = () => {
        setParkingTableData({"columns" : reserveParkingColumns, "rows":[]})
        ParkingService.getMyReservation()
        .then((res) => {
            console.log(res.data);
            setParkingTableData( {
                "columns": reserveParkingColumns,
                "rows": res.data.map(function(element) {
                        return {
                              id: element.id,
                              // unit: element.unit,
                              parking: element.parking,
                              level: element.level,
                              spot: element.spot,
                              date: element.date,
                              start: element.start_time,
                              end: element.end_time,
                              action: (
                                  <div>
                                      <GHIconButton onClick={clickItem} rowName={element.id + ""} id={ element.id} action={"cancel"}
                                                    title={"Cancel Booking"} color={"error"}
                                                    icon={"cancel"} size={"medium"} />
                                  </div>
                              ),
                            }
                    })
            })

        })
        .catch((error) => {
          console.log(error);
        });
    }

    React.useEffect(()=>{
        loadParkingList();
        loadParkingReserved();
        loadSpotTypeList();
    },[ setElevatorBooking, setSelectedParking, setParkingList]);
    const renderMain = () => {
        return (
            <Card>
                {renderDialog}
                {renderSB}
                <MDTypography ml={2} variant="h3" fontWeight="medium" color="dark" mt={1}></MDTypography>
                <MDBox mt={2} pb={3} px={2}>

                      <Grid container  spacing={3}>
                        <Grid item xs={12} md={4}>
                           <MDBox coloredShadow={"dark"}  variant={"gradient"} shadow={2} borderRadius={"3"}   >
                              <Stepper elevation={2} aria-busy={"true"}  activeStep={activeStep} orientation="vertical" nonLinear={true}
                                       autoSave={true} sx={{"padding":1}} >
                                {steps.map((step, index) => (
                                  <Step key={step.label}>
                                    <StepLabel sx={StepLabelClass}
                                      optional={null }
                                    >
                                      {step.label}
                                    </StepLabel>
                                    <StepContent sx={{"marginLeft":0.85,color:"white"}} >
                                      <Typography variant={"h5"} color={"#ffffff"} sx={{"marginBottom":1.3}}>{step.description}</Typography>
                                        {step.input}
                                      <MDBox sx={{ mb: 2 }}>
                                        <div>
                                          <MDButton
                                            variant="contained"
                                            onClick={handleNext}
                                            sx={{ mt: 1, mr: 1 }}
                                          >
                                            {index === steps.length - 1 ? 'Confirm And Book' : 'Continue'}
                                          </MDButton>
                                          <MDButton
                                              variant={"outlined"}
                                            disabled={index === 0}
                                            onClick={handleBack}
                                            sx={{ mt: 1, mr: 1 }}
                                          >
                                            Back
                                          </MDButton>
                                        </div>
                                      </MDBox>
                                    </StepContent>
                                  </Step>
                                ))}
                              </Stepper>
                              {activeStep === steps.length && (
                                <Paper square elevation={0} sx={{ p: 3 }}>
                                  <Typography>All steps completed - you&apos;re finished</Typography>
                                  <MDButton onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                                    Reserve New Parking
                                  </MDButton>
                                </Paper>
                              )}
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={8} minHeight={"220px"}>
                          <MDBox coloredShadow={"dark"}  variant={"gradient"} shadow={2} borderRadius={"3"}   >
                            <Card  >
                                <MDTypography sx={{"marginLeft":2, "marginTop":2, "marginBottom":2,}}
                                              color={"dark"} fontWeight={"bold"} >Old Reserved Data</MDTypography>
                                <DataTable
                                 table={parkingTableData}
                                    canSearch={false}
                                 showTotalEntries={false}
                                 entriesPerPage={false}
                                 noEndBorder={false}
                                 isSorted={true} />
                            </Card>
                          </MDBox>
                        </Grid>
                      </Grid>
                </MDBox>
            </Card>
          );
    }

    return (
        <DashboardLayout>
          <DashboardNavbar />
          {renderMain()}
          <Footer />
        </DashboardLayout>
      );
}

export default ParkingReserve;