import axios from "axios";
import {getPrivateURL, getPublicURL} from "./core.service";

axios.defaults.withCredentials = true;

export const getKeyTypes = () => {
  return axios.get(`${getPrivateURL()}key_type/` , {withCredentials:true} );
};
export const insertKeyType = (data) => {
  return axios.post(`${getPrivateURL()}key_type/` , data, {withCredentials:true} );
};
export const removeKeyType = (type_id) => {
  return axios.delete(`${getPrivateURL()}key_type/` + type_id , {withCredentials:true} );
};
export const getKeys = (building_id) => {
  if (building_id === null || building_id === undefined){
    building_id = "";
  }
  return axios.get(`${getPrivateURL()}keys/` + building_id, {withCredentials:true} );
};
export const getBuildingKeys = (building) => {
  return axios.get(`${getPrivateURL()}keys/`, {withCredentials:true} );
};
export const insertKey = (values) => {
  return axios.post(`${getPrivateURL()}keys/`, values, {withCredentials:true} );
};
export const removeKey = (Package_id ) => {
  return axios.delete(`${getPrivateURL()}keys/${Package_id}`, {withCredentials:true});
};
export const disableKey = (key_id ) => {
  return axios.patch(`${getPrivateURL()}keys/${key_id}`, {withCredentials:true});
};
export const updateKey = (key_id, data ) => {
  return axios.put(`${getPrivateURL()}keys/${key_id}`, data, {withCredentials:true});
};


export const getTraders = () => {
  return axios.get(`${getPrivateURL()}traders/`, {withCredentials:true} );
};
export const insertTrader = (values) => {
  return axios.post(`${getPrivateURL()}traders/`, values, {withCredentials:true} );
};
export const removeTrader = (Package_id ) => {
  return axios.delete(`${getPrivateURL()}traders/${Package_id}`, {withCredentials:true});
};
export const disableTrader = (trader_id ) => {
  return axios.patch(`${getPrivateURL()}traders/${trader_id}`, {withCredentials:true});
};
export const updateTrader = (trader_id, data ) => {
  return axios.put(`${getPrivateURL()}traders/${trader_id}`, data, {withCredentials:true});
};

export const getBorrows = () => {
  return axios.get(`${getPrivateURL()}borrow/`, {withCredentials:true} );
};
export const insertBorrow = (values) => {
  return axios.post(`${getPrivateURL()}borrow/`, values, {withCredentials:true} );
};
export const removeBorrow = (borrow_id ) => {
  return axios.delete(`${getPrivateURL()}borrow/${borrow_id}`, {withCredentials:true});
};
// export const removeTrader = (Package_id ) => {
//   return axios.delete(`${getPrivateURL()}traders/${Package_id}`, {withCredentials:true});
// };
// export const disableTrader = (trader_id ) => {
//   return axios.patch(`${getPrivateURL()}traders/${trader_id}`, {withCredentials:true});
// };
// export const updateTrader = (trader_id, data ) => {
//   return axios.put(`${getPrivateURL()}traders/${trader_id}`, data, {withCredentials:true});
// };


const KeysService = {
  getKeys,
  insertKey,
  updateKey,
  removeKey,
  disableKey,

  getBorrows,
  insertBorrow,

  getTraders,
  insertTrader,
  removeTrader,
  removeBorrow,
  updateTrader,
  disableTrader,

  getKeyTypes,
  insertKeyType,
  removeKeyType,
};
export default KeysService;
