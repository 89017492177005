
import { useState } from "react";
import MDBox from "../../../../../../components/MDBox";
import MDTypography from "../../../../../../components/MDTypography";
import Card from "@mui/material/Card";
import MDButton from "../../../../../../components/MDButton";


function QuestionApprove({values, setValues, ...rest}) {
    let [validList, setValidList] = useState([]);
    const onClick =(e)=>{

        let id = e.target.id.split("_")[0];
        let id_f = e.target.id.split("_")[1];
        if (id_f === "f"){
            document.getElementById(id + "_t").style.color  = "white"
        }else{
            document.getElementById(id + "_f").style.color  = "white"
        }
        e.target.style.color  = '#7CFC00';

        let question = e.target.getAttribute("question");
        let value = e.target.getAttribute("value");
        let correct = e.target.getAttribute("correct");

        if(value === correct && validList.indexOf(question) === -1 ){

            validList.push(question);
            // setValidList({newList})
        }else if(value !== correct && validList.indexOf(question) > -1){
            //const newList = validList.concat({ question });
            validList = validList.filter(item => item !== question)

            // const newList = validList.(question);
            //setValidList(newList)
        }
        // console.log(validList);
        //
        // let split_text = id.split("|");
        // if (split_text.length > 0){
        //     const result = split_text[split_text.length - 1]
        //     const rresult = split_text[split_text.length - 2]
        //     const question = split_text[0];
        //     if(result === rresult && validList.questions.indexOf(question) === -1){
        //         // validList.push(question);
        //         setValidList({
        //             questions:[...validList, question]
        //         })
        //     }
        console.log(validList);
        if (validList.length === values.questions.length){
            setValues({...values, isValid:true})
        }else{
            setValues({...values, isValid:false})
        }
        //     // setTrueValue(question, result, rresult)
        // }
    }
    // console.log(validList);
    const renderQuestions = () => {
        let questions = []
        let i = 1;
        values.questions.forEach(function (data) {
            i++;
            questions.push(
                <MDBox key={data["question_text"]} component="li" display="flex" alignItems="center"
                       py={1} mb={1} sx={{"borderTop":1}}>
                  <MDBox display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
                    <MDTypography variant="h6" color="text" >
                      {data["question_text"]}
                    </MDTypography>
                  </MDBox>
                  <MDBox ml="auto">
                    <div>
                        <MDButton component="a"
                                  sx={{"margin-top":6}}
                                  id={i.toString() + "_t"}
                                  question={data["question_text"].toString()}
                                  value={"true"}
                                  correct={data["correct_answered"].toString()}
                                  onClick={onClick}
                                  target="_blank" rel="noreferrer" style={{marginRight:3}}
                                  variant="gradient"
                                  color={"secondary"} >
                            Yes
                        </MDButton>

                        <MDButton component="a"
                                  sx={{"margin-top":6}}
                                  id={i.toString()+ "_f"}
                                  question={data["question_text"].toString()}
                                  value={"false"}
                                  correct={data["correct_answered"].toString()}
                                  onClick={onClick}
                                  target="_blank" rel="noreferrer" style={{marginRight:3}} variant="gradient"
                                  color={"secondary"} >
                            No
                        </MDButton>
                      </div>
                  </MDBox>

                </MDBox>)
        });
        return questions;
    }
    const doAllAndNext = (e) => {
        setValues({...values, isValid:true, autoNext:true});
    }
    // console.log(rights);
    return (
      <Card sx={{ height: "100%", boxShadow: true }}>
          <MDBox pt={2} px={2}>
            <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                Questions
            </MDTypography>
            <MDTypography variant="h6" sx={{"float":"right"}} fontWeight="medium" textTransform="capitalize">
                <MDButton variant={"gradient"} color={"success"} size={"small"}
                          onClick={doAllAndNext}>Approve All & Next</MDButton>
            </MDTypography>
            <MDTypography variant="caption" fontWeight="light" textTransform="capitalize">
                Please answer these questions before booking
            </MDTypography>
          </MDBox>
          <MDBox p={2}>
            <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
              {renderQuestions()}
            </MDBox>
          </MDBox>
      </Card>
    )
}

export default QuestionApprove;