import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import BookingCard from "examples/Cards/BookingCard";

// Data
import reportsBarChartData from "layouts/dashboards/analytics/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboards/analytics/data/reportsLineChartData";
import dataTableDataAdmin from "./data/dataTableDataAdmin";
import dataTableDataUser from "./data/dataTableDataUser";

// Images
import booking1 from "assets/images/products/product-1-min.jpg";
import booking2 from "assets/images/products/product-2-min.jpg";
import booking3 from "assets/images/products/product-3-min.jpg";
import Card from "@mui/material/Card";
import {Link, useNavigate} from "react-router-dom";
import DataTable from "../../../examples/Tables/DataTable";
import {getUserIsLogin, getUserRole} from "../../../services/auth.service";
import MDButton from "../../../components/MDButton";
import dataTableData from "../sales/data/dataTableData";
import {useEffect} from "react";
import {userColumns, userDashboardColumns} from "../../../components/Utils/HeaderColumns";
import ClientsService from "../../../services/clients.service";
import MDBadge from "../../../components/MDBadge";
import GHIconButton from "../../../components/GHIconButton";
import {useState} from "react";
import AmenitiesService from "../../../services/amenities.service";
import DashboardService from "../../../services/dashboard.service";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Image} from "react-bootstrap";
import {Dialog, DialogContent} from "@mui/material";
import Button from "@mui/material/Button";
import MDDatePicker from "../../../components/MDDatePicker";
import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import FormField from "../../pages/account/components/FormField";
import BasicFilteringGrid from "../../../examples/Tables/DataGridView";
import DataGridView from "../../../examples/Tables/DataGridView";
import {GridColDef, GridRowsProp} from "@mui/x-data-grid";
import {randomCreatedDate, randomTraderName, randomUpdatedDate} from "@mui/x-data-grid-generator";
import UserService from "../../../services/user.service";
import {modalStyle} from "../../../components/Utils/ModalStyle";
import MDAvatar from "../../../components/MDAvatar";
import CoreService from "../../../services/core.service";
import {LabelImportant} from "@mui/icons-material";
import dayjs from "dayjs";



function Analytics() {
    const { sales, tasks } = reportsLineChartData;
    useEffect(()=>{
    if(localStorage.getItem("is_first_refresh") === null ||
        localStorage.getItem("is_first_refresh") === undefined ||
        localStorage.getItem("is_first_refresh") === ""){
            localStorage.setItem("is_first_refresh", true);
            window.location.reload(true)
        }
    }, [])
    const getLastNDays = (n) =>{
        const today = new Date();
        const priorDate = new Date(new Date().setDate(today.getDate() - n));
        return priorDate.getFullYear().toString() + "-" + (priorDate.getMonth()+1).toString() + "-" + priorDate.getDate().toString()
    }

    const [qrCodeModuleShow, setQrCodeModuleShow] = useState(false);
    const [userInfoModuleShow, setUserInfoModuleShow] = useState(false);
    const [qrCodeData, setQrCodeData] = useState("");
    const handleClickOpenQrCode = () => {
        setQrCodeModuleShow(true);
    };
    const handleCloseQrCode = () => {
        setQrCodeModuleShow(false);
    };
    const handleClickOpenUserInfo = () => {
        setUserInfoModuleShow(true);
    };
    const handleCloseUserInfo = () => {
        setUserInfoModuleShow(false);
    };


    const [selectedUnit, setSelectedUnit] = useState("")
    const [selectedFirstName, setSelectedFirstName] = useState("")
    const [selectedLastName, setSelectedLastName] = useState("")
    const [selectedBuilding, setSelectedBuilding] = useState("")
    const [selectedClient, setSelectedClient] = useState("")
    const [selectedAddress, setSelectedAddress] = useState("")
    const [selectedPostalCode, setSelectedPostalCode] = useState("")
    const [selectedPhone, setSelectedPhone] = useState("")


    const renderModal = () => {
        return  <Dialog open={qrCodeModuleShow} onClose={handleCloseQrCode} aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                    <DialogContent>
                        <Image src={qrCodeData} width={512} height={512}/>
                        <MDButton size={"large"} src={""} color={"primary"} fullWidth>Download</MDButton>
                    </DialogContent>
            </Dialog>
    };
    const renderUserInfoModal = () => {
        return <Modal open={userInfoModuleShow}   color={"warning"} onClose={handleCloseUserInfo}>
          <MDBox sx={modalStyle}>
            <Card id="basic-info" sx={{ overflow: "visible" }}>
              <MDBox p={3} pb={2}>
                <MDTypography variant="h6">User Info ({selectedFirstName} {selectedLastName})</MDTypography>
              </MDBox>
              <MDBox component="form" pb={3}  >
                  <Grid container justifyContent={"center"} >
                    <Grid container spacing={2} pt={2} px={2} xs={12} md={12}   >

                        <Grid item xs={12} md={12}>
                            <MDTypography fontWeight={"bold"} textTransform={"capitalize"}   >
                                <LabelImportant color={"warning"}  />
                                Name: {selectedFirstName} {selectedLastName}</MDTypography>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <MDTypography fontWeight={"bold"}    >
                                <LabelImportant color={"warning"} />
                                Unit: {selectedUnit}</MDTypography>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <MDTypography fontWeight={"bold"}    >
                                <LabelImportant color={"warning"} />
                                Client/Building: {selectedClient} / {selectedBuilding}</MDTypography>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <MDTypography fontWeight={"bold"}    >
                                <LabelImportant color={"warning"} />
                                Postal: {selectedPostalCode}</MDTypography>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <MDTypography fontWeight={"bold"} textTransform={"capitalize"}   >
                                <LabelImportant color={"warning"} />
                                Phone: {selectedPhone}</MDTypography>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <MDTypography fontWeight={"bold"} textTransform={"capitalize"}   >
                                <LabelImportant color={"warning"} />
                                Address: {selectedAddress}</MDTypography>
                        </Grid>


                    </Grid>
                  </Grid>
              </MDBox>
            </Card>
          </MDBox>
        </Modal>
    };

    const clickOnRow = (e) =>{
        let target = e.target.getAttribute("rowname");
        if(target === "download"){
            let qr = e.target.getAttribute("qr");
            setQrCodeData(qr);
            handleClickOpenQrCode();
        }else if(target==="cancel"){
            if(confirm("do you want cancel this reservation booking ? ")){
                AmenitiesService.cancelBooking(e.target.getAttribute("id"))
                    .then((data)=>{
                        // alert("ok");
                        window.location.reload(true);
                    }).catch((reason) =>{
                        alert(reason);
                    });
            }
        }
    }


    const [todayBooking, setTodayBooking] = useState(0);
    const [membersCount, setMembersCount] = useState(0);
    const [revenue, setRevenue] = useState(0);
    useEffect(()=>DashboardService.getDashboard()
      .then((data)=>{
          setTodayBooking(data.data["today_booking"]);
          setMembersCount(data.data["member_count"]);
          setRevenue(data.data["all_revenue"]);
          renderRows(30, 10)

      }).catch((reason)=>{
          console.log("error in fetching dashboard data" + reason);
      }),[setTodayBooking, setMembersCount, setRevenue])


    // const [currentRow, setCurrentRow] = useState({});
    const showDetails = (currentRow) => {
        // alert(currentRow)
        console.log(currentRow)
        // setCurrentRow(currentRow)
        handleClickOpenUserInfo()
        setSelectedUnit(currentRow.user_unit)
        setSelectedFirstName(currentRow.first_name)
        setSelectedLastName(currentRow.last_name)
        setSelectedBuilding(currentRow.user_building)
        setSelectedAddress(currentRow.user_address)
        setSelectedPostalCode(currentRow.user_postal_code)
        setSelectedPhone(currentRow.user_phone)
        setSelectedClient(currentRow.client)
        return <></>
    }
    const renderDetailsButton = (params ) =>{
        return (
            <strong>
                <MDButton
                    variant="text"
                    color="dark"
                    size="small"
                    style={{ marginLeft: 3 }}
                    onClick={(e)=>{
                        showDetails(params.row)
                    }} >
                    {params.row.full_name}
                </MDButton>
            </strong>
        )
    }
    const renderCancelButton = (params ) =>{
        if (params.row.status === "confirmed"){
            return (
                <strong>
                    <MDButton
                        variant="gradient"
                        color="warning"
                        size="small"
                        rowname={"cancel"}
                        id={params.row.id}
                        style={{ marginLeft: 16 }}
                        onClick={clickOnRow}
                    >
                        Cancel
                    </MDButton>
                </strong>
            )
        }

    }
    const renderStatus = (params ) =>{
        return (
            <strong>
                <MDBadge
                    variant="gradient"
                    badgeContent={params.row.status}
                    color={params.row.status_color}
                    size="small"
                    container
                />
            </strong>
        )
    }
    const [columns, setColumns] = useState([
        {
            field: 'full_name',
            headerName: 'Name',
            width:170,
            renderCell: renderDetailsButton,
            editable: false,
            sortable: false,
            disableClickEventBubbling: true,
        },
        {
            field: 'amenity_name',
            headerName: 'Amenity',
            width:150,
            editable: false
        },
        {
            field: 'building',
            headerName: 'building',
            width:150,
            editable: false,
        },
        {
            field: 'booking_date',
            headerName: 'BookingDate',
            type: 'datetime',
            width:220,
            editable: false,
            valueFormatter: (params) => new dayjs(params.value).
                        subtract(-(new Date().getTimezoneOffset()), 'minutes').format('DD/MM/YYYY' ),
        },
        {
            field: 'booking_time',
            headerName: 'BookingTime',
            type: 'time',
            width:170,
            editable: false,
        },
        {
            field: 'status',
            headerName: 'Status',
            width:120,
            editable: false,
            renderCell: renderStatus,
            disableClickEventBubbling: true,
            sortable: false,
        },
        {
            field: 'action',
            headerName: 'Action',
            width:120,
            editable: false,
            renderCell: renderCancelButton,
            sortable: false,
            disableClickEventBubbling: true,
        },
    ]);
    const [rows, setRows] = useState( []);

    const renderRows = (start, end) =>{
        AmenitiesService.getBookings(getLastNDays(start), getLastNDays(-end))
            .then((result)=>{
                let tempRows  = []
                if (result.data !== undefined && result.data !== null){
                    result.data.forEach((data)=>{
                        // console.log(new Date(data['booking_date'] ))
                        tempRows.push({
                                      id: data['id'],
                                      full_name:data['full_name'],
                                      amenity_name:data['amenity_name'],
                                      status:data['status'],
                                      building:data['building'],
                                      booking_date:new Date(data['booking_date']),
                                      booking_time:data['booking_time'],
                                      status_color:data['status_color'],
                                      user_unit: data['user_unit'],
                                      user_building: data['user_building'],
                                      user_postal_code: data['user_postal_code'],
                                      user_address: data['user_address'],
                                      user_phone: data['user_phone'],
                                      first_name: data['first_name'],
                                      last_name : data['last_name'],
                                      email: data['email'],
                                      client: data['client'],
                                  });
                    });
                }
                setRows(tempRows);
            }).catch((e)=>{
               console.log(e)
            });
    }
    const renderStaffDashboard = () => {
      return (<MDBox py={3}>
          <MDBox mt={1.5}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    color="dark"
                    icon="weekend"
                    title="Today Booking"
                    count={todayBooking}
                    percentage={{
                      color: "success",
                      amount: "",
                      label: "than last week",
                    }}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    icon="leaderboard"
                    title="All Members"
                    count={membersCount}
                    percentage={{
                      color: "success",
                      amount: "",
                      label: "all member registered",
                    }}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    color="success"
                    icon="store"
                    title="Revenue $CAD"
                    count={revenue}
                    percentage={{
                      color: "success",
                      amount: "0%",
                      label: "than yesterday",
                    }}
                  />
                </MDBox>
              </Grid>

            </Grid>
          </MDBox>

          {renderModal()}
          {renderUserInfoModal()}
          <MDBox mb={3}>
            <Card>
                <MDBox p={1} lineHeight={1}>
                    <MDTypography variant="h5" fontWeight="medium">
                      Your building last ticket reserve
                    </MDTypography>
                    <MDTypography variant="button" color="text">
                      List of your building tickets reserve
                    </MDTypography>
                </MDBox>
                <MDBox p={3} lineHeight={1}>
                    <MDButton sx={{"margin":"10px"}} onClick={(e)=>renderRows(30, 10)} color={"primary"}
                              size={"small"} variant={"gradient"}>All</MDButton>

                    <MDButton sx={{"margin":"10px"}} onClick={(e)=>renderRows(30, 0)} color={"primary"}
                              size={"small"} variant={"gradient"}>Last 30 Days</MDButton>

                    <MDButton sx={{"margin":"10px"}} onClick={(e)=>renderRows(7, 0)} color={"primary"}
                              size={"small"} variant={"gradient"}>Last 7 Days</MDButton>

                    <MDButton sx={{"margin":"10px"}} onClick={(e)=>renderRows(1, 0)} color={"primary"}
                              size={"small"} variant={"gradient"}>Yesterday</MDButton>

                    <MDButton sx={{"margin":"10px"}} onClick={(e)=>renderRows(0, 0)} color={"primary"}
                              size={"small"} variant={"gradient"}>Today</MDButton>

                    <MDButton sx={{"margin":"10px"}} onClick={(e)=>renderRows(0, 1)} color={"primary"}
                              size={"small"} variant={"gradient"}>Tomorrow</MDButton>

                    <DataGridView columns={columns} rows={rows}
                                  hideColumns={{
                                        user_unit: false,
                                        user_phone: false,
                                        user_address: false,
                                        user_postal_code: false,
                                        user_building: false,
                                    }} />
                </MDBox>
            </Card>
          </MDBox>
        </MDBox>)
    }

     const renderUserDashboard = () => {
         return <MDBox py={3}>
                <MDBox mt={3} >
                <Card>
                    <MDBox p={3} lineHeight={1}>
                        <MDTypography variant="h5" fontWeight="medium">
                          Last Amenities Reserved
                        </MDTypography>
                        <MDTypography variant="button" color="text">
                          List of your tickets reserve
                        </MDTypography>
                    </MDBox>
                    <MDBox p={3} lineHeight={1}>
                        <DataGridView columns={columns} rows={rows}></DataGridView>
                    </MDBox>
                </Card>
                </MDBox>
            </MDBox>
     }

    const renderAdminDashboard = () => (
        <MDBox py={3}>
      <MDBox mt={1.5}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="weekend"
                title="Bookings"
                count={281}
                percentage={{
                  color: "success",
                  amount: "+55%",
                  label: "than lask week",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="leaderboard"
                title="Today's Members"
                count="2,300"
                percentage={{
                  color: "success",
                  amount: "+3%",
                  label: "than last month",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="store"
                title="Revenue"
                count="34k"
                percentage={{
                  color: "success",
                  amount: "+1%",
                  label: "than yesterday",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="person_add"
                title="Followers"
                count="+91"
                percentage={{
                  color: "success",
                  amount: "",
                  label: "Just updated",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox mt={6}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={3}>
              <ReportsBarChart
                color="info"
                title="website views"
                description="Last Campaign Performance"
                date="campaign sent 2 days ago"
                chart={reportsBarChartData}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={3}>
              <ReportsLineChart
                color="success"
                title="daily sales"
                description={
                  <>
                    (<strong>+15%</strong>) increase in today sales.
                  </>
                }
                date="updated 4 min ago"
                chart={sales}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={3}>
              <ReportsLineChart
                color="dark"
                title="completed tasks"
                description="Last Campaign Performance"
                date="just updated"
                chart={tasks}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox mb={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              Your Tickets
            </MDTypography>
            <MDTypography variant="button" color="text">
              List of your tickets reserve
            </MDTypography>
          </MDBox>
          <DataTable table={dataTableData} />
        </Card>
      </MDBox>
    </MDBox>
    );
    const renderSuperAdminDashboard = () => (
        <MDBox py={3}>
    <MDBox mt={1.5}>
    <Grid container spacing={3}>
      <Grid item xs={12} md={6} lg={3}>
        <MDBox mb={1.5}>
          <ComplexStatisticsCard
            color="dark"
            icon="weekend"
            title="Bookings"
            count={281}
            percentage={{
              color: "success",
              amount: "+55%",
              label: "than lask week",
            }}
          />
        </MDBox>
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <MDBox mb={1.5}>
          <ComplexStatisticsCard
            icon="leaderboard"
            title="Today's Members"
            count="210"
            percentage={{
              color: "success",
              amount: "+3%",
              label: "than last month",
            }}
          />
        </MDBox>
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <MDBox mb={1.5}>
          <ComplexStatisticsCard
            color="success"
            icon="store"
            title="Revenue"
            count="34k"
            percentage={{
              color: "success",
              amount: "+1%",
              label: "than yesterday",
            }}
          />
        </MDBox>
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <MDBox mb={1.5}>
          <ComplexStatisticsCard
            color="primary"
            icon="person_add"
            title="Followers"
            count="+91"
            percentage={{
              color: "success",
              amount: "",
              label: "Just updated",
            }}
          />
        </MDBox>
      </Grid>
    </Grid>
    </MDBox>
    <MDBox mt={6}>
    <Grid container spacing={3}>
      <Grid item xs={12} md={6} lg={4}>
        <MDBox mb={3}>
          <ReportsBarChart
            color="info"
            title="website views"
            description="Last Campaign Performance"
            date="campaign sent 2 days ago"
            chart={reportsBarChartData}
          />
        </MDBox>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <MDBox mb={3}>
          <ReportsLineChart
            color="success"
            title="daily sales"
            description={
              <>
                (<strong>+15%</strong>) increase in today sales.
              </>
            }
            date="updated 4 min ago"
            chart={sales}
          />
        </MDBox>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <MDBox mb={3}>
          <ReportsLineChart
            color="dark"
            title="completed tasks"
            description="Last Campaign Performance"
            date="just updated"
            chart={tasks}
          />
        </MDBox>
      </Grid>
    </Grid>
    </MDBox>
    <MDBox mb={3}>
    <Card>
      <MDBox p={3} lineHeight={1}>
        <MDTypography variant="h5" fontWeight="medium">
          Your Tickets
        </MDTypography>
        <MDTypography variant="button" color="text">
          List of your tickets reserve
        </MDTypography>
      </MDBox>
      <DataTable table={dataTableData} />
    </Card>
    </MDBox>
    </MDBox>
    );

    const renderMain = () => {
        const currentRole = getUserRole().toLowerCase();
        if (currentRole === "user" || currentRole === "member") {
          return renderUserDashboard();
        }
        if (currentRole === "staff") {
          return renderStaffDashboard();
        }
        if (currentRole === "admin") {
          return renderStaffDashboard();
        }
        if (currentRole === "super_admin") {
          return renderStaffDashboard();
        }
        return "";
    };

    return (
        <DashboardLayout>
          <DashboardNavbar />
          {renderMain()}
          <Footer />
        </DashboardLayout>
    );
}

export default Analytics;
