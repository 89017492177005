import axios from "axios";
import {getPrivateURL, getPublicURL} from "./core.service";

axios.defaults.withCredentials = true;

const createOrder = (productId) => {
    return axios.get(`${getPrivateURL()}order/create/${productId.toString()}`, {withCredentials:true})
};

const PaymentService = {
  createOrder,
};
export default PaymentService;
