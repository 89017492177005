import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import BookingCard from "examples/Cards/BookingCard";
// import Modal from 'react-modal';

// Images
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import { useState, useEffect } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Modal from '@mui/material/Modal';
import Autocomplete from "@mui/material/Autocomplete";
import {Link} from "react-router-dom";
import MDButton from "../../../components/MDButton";
import DataTable from "../../../examples/Tables/DataTable";
import MDSnackbar from "../../../components/MDSnackbar";
import FormField from "../account/components/FormField";
import ClientsService, {getClientsUnits, getUnitMembers} from "../../../services/clients.service";
import MDBadge from "../../../components/MDBadge";
import GHIconButton from "../../../components/GHIconButton";
import InstancesService from "../../../services/instances.service";
import TextField from "@mui/material/TextField";
import clientsService from "../../../services/clients.service";
import {getCurrentInstanceId} from "../../../services/auth.service";
import validateForm from "../../../utils";
import {buildingColumns, unitColumns, userColumns} from "../../../components/Utils/HeaderColumns";
import {modalStyle, modalStyleUser} from "../../../components/Utils/ModalStyle";
import * as Yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import CustomService from "../../../services/custome.service";


function Buildings() {
    const defaultInputValues = { buildingName: '', phoneNumber: '', postalCode: '', client: 0};

    const [values, setValues] = useState(defaultInputValues);

    const validationSchema = Yup.object().shape({
        buildingName: Yup.string()
            .min(6, 'Building Name must be at least 6 characters')
            .required('Building Name is required'),
        phoneNumber: Yup.string()
            .min(6, 'Phone Number must be at least 6 characters')
            .required('Phone Number is required'),
        postalCode: Yup.string()
            .min(3, 'Postal Code must be at least 3 numbers')
            .required('Postal Code is required'),
        client: Yup.number()
            .required('Client is required'),
    });

    const { register, handleSubmit, formState: { errors  } } =
            useForm({mode:"all", resolver:yupResolver(validationSchema)});

    const handleChange = (value) => {
        setValues(value);
        setIsValid(validationSchema.isValidSync(values));

    };


    const [isValid, setIsValid] = useState(false);


    //console.log(validationSchema.isValidSync(values));
    const [infoSB, setInfoSB] = useState(false);
    const [errorSB, setErrorSB] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const renderSB = (
        <div>
            <MDSnackbar
              color="success"
              icon="notifications"
              title="Amenity System"
              content="Your data saved successfully"
              dateTime=""
              open={infoSB}
              onClose={()=>setInfoSB(false)}
              close={()=>setInfoSB(false)}  />

            <MDSnackbar
              color="error"
              icon="error"
              title="Amenity System"
              content={errorMessage}
              dateTime=""
              open={errorSB}
              onClose={()=>setErrorSB(false)}
              close={()=>setErrorSB(false)}/>
        </div>
    );


    const [clickedItemId, setClickedItemId] = useState(0);
    const [selectedClient, setSelectedClient] = useState({});
    const [clientList, setClientList] = useState([]);
    const [openUserModel, setOpenUserModel] = useState(false);
    const [openUnitModel, setOpenUnitModel] = useState(false);

    const [openInsertModel, setOpenInsertModel] = useState(false);
    const handleOpen = (event, model) => {
        // alert(model);
        switch (model){
            case "insert":
                // setValues(defaultInputValues);
                setOpenInsertModel(true);
                break;
            case "unit":
                // setValues(defaultInputValues);
                setOpenUnitModel(true);
                break;
            case "user":
                // requestUserList(event.target.id);
                setOpenUserModel(true);
                break;
            case "confirm_delete":
                setOpenDialog(true);
                handleOpenDeleteConfirmDialog();
                break;
            case "edit":
                break;
        }
    }
    const handleClose = (model) => {
        switch (model){
            case "insert":
                setOpenInsertModel(false);
                break;
            case "user":
                setOpenUserModel(false);
                break;
            case "unit":
                setOpenUnitModel(false);
                break;
        }
    }
    useEffect(() => { loadClients(); }, []);


    const [unitTableData, setUnitTableData] = useState({"columns" :unitColumns, "rows":[]});
    const [userTableData, setUserTableData] = useState({"columns" :userColumns, "rows":[]});
    const [selectedBuildingTitle, setSelectedBuildingTitle] = useState("");




    const handleOpenDeleteConfirmDialog = () => setOpenDialog(true);
    const handleCloseDeleteConfirmDialog = () => setOpenDialog(false);
    const [openDialog, setOpenDialog] = useState(false);

    const handleAgreeDeleteDialog = () => {
            clientsService.removeBuilding(clickedItemId)
                .then((response) =>{
                    console.log(response);
                    setInfoSB(true);
                    window.location.reload();
                })
                .catch((err) => {
                    console.log(err);
                    setErrorMessage(err.target.data.status);
                    setErrorSB(true);
                });
        };
    const renderDialog = (
            <Dialog open={openDialog} keepMounted onClose={handleCloseDeleteConfirmDialog} aria-describedby="alert-dialog-slide-description" >
                <DialogTitle><Icon color={"warning"} >warning</Icon>{" Delete confirmation"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Are you sure to delete this item ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <MDButton onClick={handleCloseDeleteConfirmDialog} >Disagree</MDButton>
              <MDButton onClick={handleAgreeDeleteDialog}  size={"large"}  color={"info"}  >Agree</MDButton>
            </DialogActions>
          </Dialog>
        )



    const requestUserList = (buildingId) => {
        console.log(buildingId)
        setUserTableData({"columns" :userColumns, "rows":[]});
        const clickOnRowUser = (e) =>{
            console.log(e.target);
        }
        ClientsService.getBuildingMembers(buildingId)
            .then((res) => {
                console.log(res.data);
                let data = res.data;
                setUserTableData( {
                    "columns": userColumns,
                    "rows": data.map(function(element) {
                        return {
                                  id: element.id,
                                  username: element.user.username,
                                  name: element.user.first_name + " " + element.user.last_name,
                                  unit: element.unit,
                                  role: element.role,
                                  status: <MDBadge badgeContent={element.status} size="sm" container
                                                   color={element.status === "active" ? "success" : "error"} />,
                                  action: (
                                      <div>
                                          <GHIconButton size={"medium"} onClick={clickOnRowUser}
                                                        id={element.id} title={"Remove From Client"}
                                                        color={"error"} icon={"delete"}/>
                                      </div>
                                  ),
                            }
                    })
                })
            })
            .catch((error) => {
                console.log(error);
            });

    }


    const requestUnitList = (buildingId) => {
        console.log(buildingId)
        setUnitTableData({"columns" :unitColumns, "rows":[]});
        const clickOnRow = (elem) =>{

            let buildingToDo = elem.target.getAttribute("building");
            let actionToDo = elem.target.getAttribute("action");
            let idToDo = elem.target.getAttribute("id");
            switch (actionToDo){
                case "delete":
                    clientsService.removeMemberFromBuilding({"id":idToDo, "building": buildingToDo})
                        .then((res)=>{
                            console.log()
                        })
                        .catch((err)=>{
                            console.log(err);
                        });

            }
        }
        ClientsService.getBuildingUnits(buildingId)
            .then((res) => {
                // console.log(res.data);
                // alert(res.data);
                let data = res.data;
                setUnitTableData( {
                    "columns": unitColumns,
                    "rows": data.map(function(element) {
                        return {
                                  unit: element.unit,
                                  building : element.building,
                                  section: element.section,
                                  floor: element.floor,
                                  client: element.client,
                                  members: element.members,
                                  status: <MDBadge badgeContent={element.status} size="sm" container
                                                   color={element.status === "active" ? "success" : "error"} />,
                                  action: (
                                      <div>
                                          <GHIconButton size={"medium"} onClick={clickOnRow}
                                                        id={element.id} title={"Remove From Building"}
                                                        color={"error"} icon={"delete"} action={"remove"}  />
                                      </div>
                                  ),
                            }
                    })
                })
            })
            .catch((error) => {
                console.log(error);
                // alert(error);
            });

    }


    function handleClickItem(elem) {
        let actionToDo = elem.target.getAttribute("action");
        let rowName = elem.target.getAttribute("rowname");
        let rowId = elem.target.id;
        setClickedItemId(rowId);
        setSelectedBuildingTitle(rowName);
        switch (actionToDo) {
            // case "user_list":
            //     requestUserList(rowId)
            //     handleOpen(elem,"user");
            //     break;
            case "unit_list":
                requestUnitList(rowId)
                handleOpen(elem,"unit");
                break;
            case "edit":
                handleOpen(elem,"edit");
                break;
            case "delete":
                handleOpen(elem,"confirm_delete");
                break;
        }
    }

    function loadClients(){
        clientsService.getClients()
        .then((res) => {
            let defaultClient = {"id":"", "client_name":"-- All Clients --" }
            if(res.data === null || res.data === undefined || res.data === "") { res.data = []; }
            res.data.unshift(defaultClient);
            setClientList(res.data);
            setSelectedClient(res.data[0]);
            loadBuildings(res.data[0].id);
        })
        .catch((e) => {
            console.log(e.toString());
        });
    }



    const [buildingTableData, setBuildingTableData] = useState({"columns" : buildingColumns, "rows":[]});

    const loadBuildings = (client_id) => {

        handleChange({ ...values, client: client_id })
        if(client_id === undefined){
            client_id = "";
        }
        setBuildingTableData({"columns" : buildingColumns, "rows":[]})
        ClientsService.getClientsBuilding(client_id)
        .then((res) => {
            console.log(res.data);
            setBuildingTableData({
                "columns": buildingColumns,
                "rows": res.data.map(function(element) {
                        return {
                          id: element.id,
                          name: element.name ,
                          status: <MDBadge badgeContent={element.status} size="sm" container
                                           color={element.status === "active" ? "success" : "error"} />,
                          member: element.member ,
                          postal_code: element.postal_code,
                          phone_number: element.phone_number,
                          client: element.client,
                          members: 3,
                          action: (
                              <div>
                                  <GHIconButton onClick={handleClickItem} id={element.id} action={"unit_list"}
                                                rowName={element.name} title={"Units"} color={"info"}
                                                icon={"calendar_view_week"} size={"medium"}  />
                                  {/*<GHIconButton onClick={handleClickItem} id={element.id} action={"user_list"}*/}
                                  {/*              rowName={element.name} title={"Members"} color={"info"}*/}
                                  {/*              icon={"list_alt"} size={"medium"} disabled={true} />*/}
                                  <GHIconButton onClick={handleClickItem} id={element.id} action={"edit"}
                                                rowName={element.name} title={"Edit"} color={"warning"}
                                                icon={"edit"} size={"medium"}  />
                                  <GHIconButton onClick={handleClickItem} id={element.id} action={"delete"}
                                                rowName={element.name} title={"Remove"} color={"error"}
                                                icon={"delete"} size={"medium"}  />

                              </div>
                          ),
                        }
                    })
            })

        })
        .catch((error) => {
          console.log(error);
          setErrorMessage(error.toString());
        });
    }

    const saveDataClicked = (e) =>{
        e.preventDefault();


        handleChange({ ...values, client: selectedClient.id });
        // console.log("isValid => "+ isValid);
        if(isValid){
            ClientsService.insertBuilding(values)
                .then((response) =>{
                    console.log(response);
                    setInfoSB(true);
                    handleClose("insert");
                    loadBuildings(selectedClient.id);
                    // window.location.reload();
                })
                .catch((err) => {
                    console.log(err);
                    setErrorMessage(err.toString());
                    setErrorSB(true);
                });
        }
        handleClose();
    }


    const renderUserListModal = () => {
        // requestUserList();
        return <Modal open={openUserModel} onClose={(e)=>{handleClose("user")}} color={"warning"} >
          <MDBox sx={modalStyleUser}>
            <Card id="basic-info" sx={{ overflow: "visible" }}>
              <MDBox p={3}>
                <MDTypography variant="h5">{selectedBuildingTitle} Member's List</MDTypography>
              </MDBox>
              <MDBox component="form" pb={3} px={3}>
                 <Grid pb={2}  >
                    <MDButton color={"info"} size={"large"} variant={"gradient"}
                              to={"/users/insert?client_id=" + clickedItemId} component={Link} target={"_blank"}  >
                        <Icon>add</Icon>Add Member To {selectedBuildingTitle}
                    </MDButton>
                 </Grid>
                <Grid container spacing={3}>
                    <DataTable table={userTableData} showTotalEntries canSearch />
                </Grid>
              </MDBox>
            </Card>
          </MDBox>
        </Modal>
    };

    const renderUnitListModal = () => {
        // renderDialog();
        return <Modal open={openUnitModel} onClose={(e)=>{handleClose("unit")}} color={"warning"} >
          <MDBox sx={modalStyleUser}>
            <Card id="basic-info" sx={{ overflow: "visible" }}>
              <MDBox p={3}>
                <MDTypography variant="h5">{selectedBuildingTitle} Unit's List</MDTypography>
              </MDBox>
              <MDBox component="form" pb={3} px={3}>
                 <Grid pb={2}  >
                    <MDButton sx={{display:"none"}} color={"info"} size={"large"} variant={"gradient"}
                              to={"/users/insert?client_id=" + clickedItemId} component={Link} target={"_blank"}  >
                        <Icon>add</Icon>Add Unit To {selectedBuildingTitle}
                    </MDButton>
                 </Grid>
                <Grid container spacing={3}>

                    <DataTable table={unitTableData} showTotalEntries canSearch />
                </Grid>
              </MDBox>
            </Card>
          </MDBox>
        </Modal>
    };

    const renderInsertModal = () => {
        return <Modal open={openInsertModel} onClose={(e)=>{handleClose("insert")}} color={"warning"} >
          <MDBox sx={modalStyle}>
            <Card id="basic-info" sx={{ overflow: "visible" }}>
              <MDBox p={3}>
                <MDTypography variant="h5">Insert New Building</MDTypography>
              </MDBox>
              <MDBox component="form" pb={3} px={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <TextField
                      label="Building Name"
                      required
                      fullWidth
                      error={!!errors.buildingName}
                      helperText={errors.buildingName?.message}
                      value={values.buildingName}
                      {...register("buildingName")}
                      onChange={(event) =>
                          handleChange({ ...values, buildingName: event.target.value })}
                      placeholder="Building Name"  />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Phone Number"
                      required
                      error={!!errors.phoneNumber}
                      helperText={errors.phoneNumber?.message}
                      value={values.phoneNumber}
                      {...register("phoneNumber")}
                      onChange={(event) =>
                          handleChange({ ...values, phoneNumber: event.target.value })}
                      placeholder="+45783242342"  />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Postal Code"
                      required
                      error={!!errors.postalCode}
                      helperText={errors.postalCode?.message}
                      value={values.postalCode}
                      {...register("postalCode")}
                      onChange={(event) =>
                          handleChange({ ...values, postalCode: event.target.value })}
                      placeholder="VXF123"  />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <MDButton color={"info"} size={"large"} disabled={!isValid}
                              onClick={saveDataClicked} >Save Data</MDButton>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </MDBox>
        </Modal>
    };

    const renderBuildingList = () => {
      return(
          <MDBox my={3}>
            {renderSB}
            {renderDialog}
            {renderInsertModal()}
            {renderUserListModal()}
            {renderUnitListModal()}
            <MDBox  display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
                <MDButton variant="gradient" color="warning" size="large" disabled={selectedClient.id === ""}  onClick={(e) => {handleOpen(e,"insert")}}>
                  new Building
                </MDButton>
                <MDBox display={"flex"} >
                <Autocomplete
                    sx={{ width: 300 }}
                    value={selectedClient}
                    options={clientList}
                    getOptionLabel={(option) => option.client_name}
                    onChange={(event,value) => {
                        setSelectedClient(value);
                        loadBuildings(value.id);
                    }}
                    renderInput={(params) =>
                        <TextField  {...params} label={"Clients"} />
                    }
                  />
                </MDBox>
            </MDBox>
            <Card>
                <DataTable showTotalEntries={true} table={buildingTableData} entriesPerPage={false} canSearch />
            </Card>
          </MDBox>
      )
    };

    return (
        <DashboardLayout>
        <DashboardNavbar />
          {renderBuildingList()}
        <Footer />
      </DashboardLayout>
    );
}

export default Buildings;
