import axios from "axios";
import {getPrivateURL, getPublicURL} from "./core.service";

axios.defaults.withCredentials = true;

export const getPackages = () => {
  return axios.get(`${getPrivateURL()}packages/`, {withCredentials:true} );
};
export const getMyPackages = () => {
  return axios.get(`${getPrivateURL()}my_package/`, {withCredentials:true} );
};
export const getPackage = (Package_id) => {
  return axios.get(`${getPrivateURL()}packages/` + Package_id, {withCredentials:true} );
};
export const insertPackage = (values) => {
  return axios.post(`${getPrivateURL()}packages/`, values, {withCredentials:true} );
};
export const updatePackage = (Package_id, data ) => {
  return axios.put(`${getPrivateURL()}packages/${Package_id}`, data, {withCredentials:true});
};
export const removePackage = (Package_id ) => {
  return axios.delete(`${getPrivateURL()}packages/${Package_id}`, {withCredentials:true});
};
export const disablePackage = (Package_id ) => {
  return axios.patch(`${getPrivateURL()}packages/${Package_id}`, {withCredentials:true});
};

const PackagesService = {
  getPackages,
  getMyPackages,
  getPackage,
  insertPackage,
  updatePackage,
  removePackage,
  disablePackage,
};
export default PackagesService;
