import MDBadge from "../../../../components/MDBadge";
import {Icon} from "@mui/material";
import MDButton from "../../../../components/MDButton";
import MDTypography from "../../../../components/MDTypography";
import CustomerCell from "../../../ecommerce/orders/order-list/components/CustomerCell";
import team1 from "../../../../assets/images/team-1.jpg";
import team2 from "../../../../assets/images/team-2.jpg";
import team3 from "../../../../assets/images/team-3.jpg";
import team4 from "../../../../assets/images/team-4.jpg";
import {instanceColumns, userListColumns} from "../../../../components/Utils/HeaderColumns";
import {useEffect, useState} from "react";
import InstancesService from "../../../../services/instances.service";
import {Image} from "react-bootstrap";
import GHIconButton from "../../../../components/GHIconButton";
import UserService from "../../../../services/user.service";
import CoreService from "../../../../services/core.service";


// const dataTableDataUsers = {
//   columns: userListColumns,
//   rows: [
//     {
//         user: ["Alice Grant", { image: team1 }, "alicegrant@gmail.com"],
//         username: "Mr.Hill",
//         lastLogin : "23 hours ago",
//         client : "Atlatis Hal",
//         instance : "Hotel Booking",
//         status: <MDBadge badgeContent="active" color="success" size="sm" container />,
//         registerDate: "17/09/2022",
//         BookCount: 23,
//         role: "Member",
//         actions: (
//             <div>
//               <Icon  fontSize={"medium"} color={"info"}  sx={{padding:0.4, display: "inline", placeItems: "center"}} >email</Icon>
//               <Icon  fontSize={"medium"} color={"warning"}  sx={{padding:0.4, display: "inline", placeItems: "center"}} >key</Icon>
//                <Icon  fontSize={"medium"} color={"secondary"}  sx={{ padding:0.4, display: "inline", placeItems: "center"}} >input</Icon>
//                <Icon  fontSize={"medium"} color={"error"}  sx={{ padding:0.4, display: "inline", placeItems: "center"}} >remove_circle</Icon>
//             </div>
//         ),
//     },
//   ],
// };
//
// export default dataTableDataUsers;




function DataTableDataUsers(progressChanger,clickItem){
    // console.log('DataTableDataUsers')
    // alert("Hello")
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState(userListColumns);
    useEffect(()=> {
        progressChanger(true);
      UserService.getUsers()
        .then((res) => {
            progressChanger(false);
            setRows(res.data);
            console.log(res.data);
        })
        .catch((error) => {
            progressChanger(false)
            console.log(error);
        });
    }, [setColumns, setRows] )
    return {
        "columns": columns,
        "rows": rows.map(function(element)  {
            return {
                    // user: <CustomerCell image={CoreService.BASE_API_URL + element.avatar}
                    //                     color={element.color || "dark"}
                    //                     name={element.first_name + " " + element.last_name}
                    //                     email={element.email} />,
                    name: element.first_name + " " + element.last_name,
                    email: element.email,
                    status: (element.status === "active" ?
                                    <MDBadge badgeContent="active" color="success" size="sm" container /> :
                                    (element.status === 'wait_for_confirm') ?
                                    <MDBadge badgeContent={element.status} color="dark" size="sm" container /> :
                                    <MDBadge badgeContent={element.status} color="error" size="sm" container />),
                    lastLogin : element.last_login,
                    role: element.role,
                    // client : element.client,
                    building : element.building,
                    unit : element.unit,
                    instance : element.instance,
                    actions: (
                        <div>
                          <GHIconButton onClick={clickItem} id={element.id} action={"show_info"}   client={element.client}
                                        rowName={element.email} title={"Information"} color={"dark"} icon={"info"} size={"medium"}  />
                          <GHIconButton onClick={clickItem} id={element.id} action={"book_amenity"}     client={element.client}
                                        rowName={element.email} title={"Book Amenity"} color={"primary"} icon={"event"} size={"medium"}  />
                          <GHIconButton onClick={clickItem} id={element.id} action={"resend_confirm"}   client={element.client}
                                        rowName={element.email} title={"Resend Confirm"} color={"info"} icon={"email"} size={"medium"}  />
                          <GHIconButton onClick={clickItem} id={element.id} action={"change_password"}  client={element.client}
                                        rowName={element.email} title={"Change Password"} color={"secondary"} icon={"password"} size={"medium"} />
                          <GHIconButton onClick={clickItem} id={element.id} action={"edit"}             client={element.client}
                                        rowName={element.email} title={"Edit"} color={"warning"} icon={"edit"} size={"medium"} />
                          <GHIconButton onClick={clickItem}  id={element.id} action={"delete"}          client={element.client}
                                        rowName={element.email} title={"Delete"} color={"error"} icon={"delete"} size={"medium"} />
                          <GHIconButton onClick={clickItem}  id={element.id} action={"disable"}         client={element.client}
                                        rowName={element.name}
                                        title={element.status === "active"? "Disable" : "Active"}
                                        color={element.status === "active"? "info" : "error"}
                                        icon={element.status === "active"? "toggle_off" : "toggle_on"}
                                        size={"medium"} />
                        </div>
                    ),
            }
        }),
    }
}

export default DataTableDataUsers;
