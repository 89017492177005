// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";

// Data
import selectData from "layouts/pages/account/settings/components/BasicInfo/data/selectData";
import {categoriesList, durationList, gapList, guestList, minorList} from "../../insert/static_data";
import * as Yup from "yup";
import {useState} from "react";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {TextField} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import {useEffect} from "react";

function BookingData({values, setValues, ...other}) {

    const [checked, setChecked ] = useState(false);
    const defaultInputValues = {
        completeName: values.completeName || '',
        email: values.email || '',
        phone: values.phone || '',
        minorsCount:  '0',
        guestsCount: '0',
      };


      useEffect(() => {
          setValues({...values, isValid:true});
      }, [setValues]);
    const validationSchema = Yup.object().shape({
      completeName: Yup.string()
          .min(6, 'Complete Name must be at least 3 characters')
          .required('Complete Name is required'),
        email: Yup.string().required("email is required.").email("email is not valid"),
        phone : Yup.string().required("phone is required."),
    });

    const [insideValues, setInsideValues] = useState(defaultInputValues);
    const { register, handleSubmit, formState: { errors , isValid } } =
                        useForm({mode:"all", resolver:yupResolver(validationSchema)});


    const handleChange = (value) => {
        setInsideValues(value)
        console.log(insideValues)
        if(validationSchema.isValidSync(insideValues)){
            setValues({...values, completeName: insideValues['completeName'],
                                email: insideValues['email'],
                                phone: insideValues['phone'],
                                isValid:(validationSchema.isValidSync(insideValues))})
        }
    };
    let list = [];
    const renderMinors = () => {
        if(values.minors_count > 0){
            list = []
            for (let i = 0; i <= values.minors_count; i++) {
                list.push(i);
            }
            return(<Grid item xs={12} md={6}><Autocomplete
                      defaultValue="0"
                      options={list}
                      getOptionLabel={(option) => option.toString() || 0}
                      onChange={(e,v)=>{
                            setValues({...values,  minors: parseInt(v)})
                      }}
                      renderInput={(params) => (
                        <FormField {...params} label="Minors Count" InputLabelProps={{ shrink: true }} />
                      )}
                    /></Grid>)
        }
        return ""
    }

    const renderGusts = () => {
        if(values.guests_count > 0){
            list = []
            for (let i = 0; i <= values.guests_count; i++) {
                list.push(i);
            }
            return(<Grid item xs={12} md={6}><Autocomplete
                      defaultValue="0"
                      options={list}
                      getOptionLabel={(option) => option.toString() || 0}
                      onChange={(e,v)=>{
                          setValues({...values,  guests: parseInt(v)})
                      }}
                      renderInput={(params) => (
                        <FormField {...params} label="Guests Count" InputLabelProps={{ shrink: true }} />
                      )}
                    /></Grid>)
        }
        return ""
    }
    const checkChange = (event) =>{
        if(!event.target.checked){
            setInsideValues({...insideValues, completeName: "", email: "", phone: ""});
        }
        setChecked(event.target.checked)
    }

    return (
        <Card id="basic-info" sx={{ overflow: "visible" }}>
          <MDBox p={3}>
            <MDTypography variant="h5">Reserve Information</MDTypography>
          </MDBox>
          <MDBox component="form" pb={3} px={3}>
            <Grid container spacing={3}>

              <Grid item xs={12} md={12}>
                  <Checkbox onChange={checkChange} />
                  <MDTypography variant="button" fontWeight="regular"
                                color="text" sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}>
                      &nbsp;&nbsp;Book For Other ?&nbsp;</MDTypography>
              </Grid>
              <Grid item xs={12} md={4} sx={{"display":checked ? "flex": "none"}}>
                  <TextField type="text" label="Complete Name" required variant={"standard"} fullWidth name="completeName"
                             error={!!errors.completeName} helperText={errors.completeName?.message}
                             disabled={!checked}
                             value={insideValues.completeName}
                             {...register("completeName")}
                      onChange={(event) =>
                          handleChange({ ...insideValues, completeName: event.target.value })}

                  />
              </Grid>
              <Grid item xs={12} md={4} sx={{"display":checked ? "flex": "none"}}>
                  <TextField type="text" label="Email" required variant={"standard"} fullWidth name="email"
                             error={!!errors.email} helperText={errors.email?.message}
                             disabled={!checked}
                             value={insideValues.email}{...register("email")}
                      onChange={(event) =>
                          handleChange({ ...insideValues, email: event.target.value })}

                  />
              </Grid>
              <Grid item xs={12} sm={4} sx={{"display":checked ? "flex": "none"}}   >
                  <TextField type="tell" label="Phone" required variant={"standard"}
                             fullWidth name="phone" error={!!errors.phone}
                             disabled={!checked}
                             helperText={errors.phone?.message} value={insideValues.phone}{...register("phone")}
                      onChange={(event) =>
                          handleChange({ ...insideValues, phone: event.target.value })}
                  />
              </Grid>
                {renderMinors()}
                {renderGusts()}
            </Grid>
          </MDBox>
        </Card>
    );
}

export default BookingData;
